<template>
  <cw-page
    hero-image="application-error.jpg"
    icon="error"
    class="application__connection-error"
  >
    <v-row>
      <v-col>
        <p>
          <translate>
            The application process was interrupted by a connection error. Try again later.
          </translate>
        </p>
      </v-col>
    </v-row>
  </cw-page>
</template>

<script>
export default {
  name: 'CwConnectionError',
};
</script>
